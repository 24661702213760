import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'

import {setForm, updateForm, formsSelector, removeFormValue} from '../store.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'

export const PAYMENT_ACCOUNTS = 'PAYMENT_ACCOUNTS'

export function paymentAccountsSelector(state) {
  return (
    formsSelector(state)[PAYMENT_ACCOUNTS] || paymentAccountsSelector.default
  )
}
paymentAccountsSelector.default = {}

export function paymentAccountsHaveLoadedSelector(state) {
  return !!formsSelector(state)[PAYMENT_ACCOUNTS]
}

export function paymentAccountSelector(state, {paymentAccountID}) {
  return paymentAccountsSelector(state)[paymentAccountID]
}

export const paymentAccountsSortedByNameSelector = createSelector(
  paymentAccountsSelector,
  (paymentAccounts) =>
    sortBy(paymentAccounts, 'name').filter(({archive_date}) => !archive_date),
)

export function defaultPaymentAccountSelector(state) {
  return paymentAccountsSortedByNameSelector(state).find(
    ({is_default}) => is_default,
  )
}

export function setPaymentAccounts(paymentAccounts) {
  setForm(PAYMENT_ACCOUNTS, keyBy(paymentAccounts, 'id'))
}

export function setPaymentAccount(paymentAccount) {
  updateForm(PAYMENT_ACCOUNTS, {[paymentAccount.id]: paymentAccount})
}

export function removePaymentAccount(paymentAccountID) {
  removeFormValue(PAYMENT_ACCOUNTS, [paymentAccountID])
}

export async function getPaymentAccounts() {
  try {
    const paymentAccounts = await fetchAllAPI(
      '/company/payment/',
      'payment_account',
    )

    setPaymentAccounts(paymentAccounts)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting payment accounts.',
        details: err.message || err.error_message,
      },
      `Error getting payment accounts. ${err.error_message || err.message}`,
      err,
    )

    setPaymentAccounts([])
  }
}
